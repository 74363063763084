
import PortfolioIconGenerator, { PORTFOLIO_ICONS_KEYS } from '@/components/portfolio/icons/PortfolioIconGenerator'
import PortfolioService from '@/service/PortfolioService'
import Alert from "@/components/Alert";

export const UPLOAD_ICON_STATUSES = {
    none: 0,
    initial: 1,
    uploading: 2,
    fail: 3,
    success: 4
}
const MAX_ICON_SIZE = 5 * 1024 * 1024; // 5mb
const NUMBER_VISIBLE_ICON_DEFAULT = 13;

export default {
    name: 'CustomPortfolioIcon',
    components: {
        PortfolioIconGenerator,
        Alert
    },

    props: {
        status: {
            type: Number,
            default: UPLOAD_ICON_STATUSES.none
        },
        selectedIconKey: {
            type: String,
            default: ''
        },
        customIconUrl: {
            type: String,
            default: ''
        },
        customIconId: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            showedMore: false,
            uploadIconStatuses: UPLOAD_ICON_STATUSES,
            file: null,
            errorMessage: '',
            dragover: false,
        }
    },

    computed: {
        portfolioIconKeys() {
            return this.showedMore ? PORTFOLIO_ICONS_KEYS : PORTFOLIO_ICONS_KEYS.slice(0, NUMBER_VISIBLE_ICON_DEFAULT)
        },
        uploadIcon() {
            return this.$vuetify.theme.dark ?
                require('@/assets/add_porfolio/dark/upload-icon.svg') :
                require('@/assets/add_porfolio/light/upload-icon.svg');
        },
        uploading() {
            return this.status === UPLOAD_ICON_STATUSES.uploading
        }
    },

    watch: {
        status(curStatus) {
            if (curStatus !== UPLOAD_ICON_STATUSES.fail) {
                this.errorMessage = null
            }
        }
    },

    methods: {
        handleClickIcon(key) {
            if (this.selectedIconKey === key) {
                // deselect icon
                this.$emit('update:iconKey', '')
            } else {
                this.$emit('update:iconKey', key)
            }
        },
        handleClickCustomIcon() {
            this.$emit('update:status', UPLOAD_ICON_STATUSES.initial)
        },
        selectedFileChange(file) {
            this.file = file
            if (file) {
                this.handleUploadIcon()
            }
        },
        onDrop(e) {
            this.dragover = false
            const files = Array.from(e.dataTransfer.files)
            this.file = files[0]
            if (this.file) {
                this.handleUploadIcon()
            }
        },
        validateFile() {
            if (!this.file) {
                return
            }

            if (!['image/png', 'image/svg+xml', 'image/jpeg'].includes(this.file.type)) {
                return this.$t('component.portfolio.addPortfolio.unsupportedFile')
            }

            if (this.file.size > MAX_ICON_SIZE) {
                return this.$t('component.portfolio.addPortfolio.fileTooLarge')
            }
    
            return ''
        },
        async handleDeleteCustomIcon() {
            if (!this.customIconId) {
                return
            }
            try {
                await this.portfolioService.deleteIcon(this.customIconId)
                this.$emit('update:customIconUrl', '')
                this.$emit('update:customIconId', null)
            } catch (e) {
                this.errorMessage = e.response?.statusText ?? e.message ?? this.$t('common.errors.generalError')
            }
        },
        async handleUploadIcon() {
            this.errorMessage = this.validateFile()
            if (this.errorMessage !== '') {
                return
            }
            this.$emit('update:status', UPLOAD_ICON_STATUSES.uploading)
            try {
                const res = await this.portfolioService.uploadIcon(this.file)

                this.$emit('update:status', UPLOAD_ICON_STATUSES.success)
                this.$emit('update:customIconUrl', res.data[0].url)
                this.$emit('update:customIconId', res.data[0].id)
            } catch (e) {
                this.errorMessage = e.response?.statusText ?? e.message ?? this.$t('common.errors.generalError')
                this.$emit('update:status', UPLOAD_ICON_STATUSES.fail)
            } finally {
                this.file = null
            }
        }
    },

    created() {
        this.portfolioService = new PortfolioService(this.$axios)
    }
}
