
export default {
    name: 'PageNavigation',
    computed: {
        isLoggedIn() {
            return !!this.$store.state.account.user
        },
        pageConfigurations() {
            const locale = this.$store.state.preferences.locale
            const pages = [
                {
                    displayedText: this.$t('components.pageNavigation.portfolio', {}, locale),
                    targetRoute: '/finance/dashboard/',
                    routePathKey: 'finance'
                }
            ]
            return pages.filter(p => this.isLoggedIn || p.isPublic)
        }
    },
    methods: {
        isSelectedPage(routePathKey) {
            const currentRoutePath = this.$route.fullPath
            const translatedRoutePathKey =  this.$getTranslatedRoutePath(`/${routePathKey}/`)
            if (currentRoutePath?.startsWith(translatedRoutePathKey)) {
                return true
            }

            return false
        },
        handleNavigatePage(page) {
            const { targetRoute, routeNameKey } = page 
            if (this.isSelectedPage(routeNameKey)) {
                return
            }

            this.$router.push({
                path: this.$getTranslatedRoutePath(targetRoute)
            })
        }
    }
}
