import VDialog from 'vuetify/es5/components/VDialog'

export default {
    extends: VDialog,

    name: 'spatz-dialog',

    props: {
        submitCallback: Function,
        width: {
            type: [String, Number],
            default: 'fit-content'
        }
    },
    

    methods: {
        onKeydown(e) {
            if ((e.key === 'Escape') && !this.getOpenDependents().length) this.closeSpatzDialog()
            if ((e.key === 'Enter') && !this.getOpenDependents().length) this.confirmSpatzDialog()

            this.$emit('keydown', e);
        },

        closeSpatzDialog() {
            if (!this.persistent) {
                this.isActive = false;
                const activator = this.getActivator();
                this.$nextTick(() => activator && activator.focus());
            } else if (!this.noClickAnimation) {
                this.animateClick();
            }
        },

        confirmSpatzDialog() {
            this.submitCallback()
        }
    }
}
