const state = () => ({
    type: 'success',
    message: ''
});

const mutations = {
    updateType(state, newType) {
        state.type = newType;
    },
    updateMessage(state, newMessage) {
        state.message = newMessage;
    },
};

export default {
    state,
    mutations,
}
