
import UserMessage from "@/components/UserMessage";

export default {
    props: [
        'value',
    ],
    components: {
        UserMessage,
    },
    methods: {
        onDialogInput(event) {
            if (event) {
                this.isRead = this.value.isRead;

                return;
            }
            this.$emit('input', event);
            if (!this.isRead) {
                this.$root.$emit('user-message-read');
            }
        },
    },
}
