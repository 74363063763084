
class TransactionService {
    lastError;
    lastResponseCode;

    constructor(axios) {
        this.axios = axios
    }

    async updateInline(requestParam, body, $t) {
        const {portfolioId, positionId, transactionId} = requestParam;
        let url = `/api/portfolio/${portfolioId}/position/${positionId}/transaction/${transactionId}`

        await this.axios({
            method: "put",
            url: url,
            data: body
        })
        .then((res) => {
            this.lastResponseCode = res.status
            return true
        })
        .catch((res) => this.handleError(res, $t));
    }

    async save(postData, $t) {
        const requestData = {
            mic: postData.mic,
            qty: postData.qty,
            purchasePrice: postData.price,
            purchaseDate: postData.date,
            taxes: postData.taxes,
            costs: postData.costs,
            brokerId: postData.broker,
            currency: postData.currency,
            taxesCurrency: postData.taxesCurrency,
            costsCurrency: postData.costsCurrency,
        }

        let url = `/api/portfolio/${postData.portfolioId}/position/${postData.positionId}/transaction`
        let method = "post"

        if(postData.transactionId)
        {
            url = url+'/'+postData.transactionId
            method = "put"
        }

        await this.axios({
            method: method,
            url: url,
            data: requestData
        })
        .then((res) => {
            this.lastResponseCode = res.status
            return true
        })
        .catch((res) => this.handleError(res, $t));
    }

    async getAllForPosition(opt, $t) {
        return await this
            .axios
            .get(`/api/portfolio/${opt.portfolioId}/position/${opt.positionId}/transactions`)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error, $t);
            })
    }

    async getRealizedTransactions(opt, $t) {
        if (opt.positionId && !opt.portfolioId) throw new Error($t('service.transaction.missingPortfolioId'));

        let url = '/api/portfolio'
        if(opt.portfolioId) url += '/'+opt.portfolioId
        if(opt.positionId) url += '/position/'+opt.positionId+'/transactions'
        url += `/realized?period=${opt.period}`

        return await this
            .axios
            .get(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error, $t);
            })
    }

    async getPortfolioTransactions(portfolioId, limit = null, interval = 'max', $t) {
        let url = '/api/portfolio/last-transactions'
        if(portfolioId) url += '/'+portfolioId

        return await this
            .axios
            .get(url, {
                params: {
                    limit: limit,
                    period: interval,
                }
            })
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error, $t);
            })
    }

    async delete(portfolioId, positionId, transactionId, $t) {
        if(!portfolioId) portfolioId = 0
        if(!positionId) throw new Error($t('service.transaction.missingPositionId'));
        if(!transactionId) throw new Error($t('service.transaction.missingTransactionId'));

        const url = `/api/portfolio/${portfolioId}/position/${positionId}/transaction/${transactionId}`

        return await this
            .axios
            .delete(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error, $t);
            })
    }

    async deleteAll(transactionIds, $t) {
        let  url = '/api/transaction/massDelete'

        return await this
            .axios
            .post(url, { ids: transactionIds })
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error, $t);
            })
    }

    async query(endpoint, $t) {
        return await this
            .axios
            .get(`/api/${endpoint}`)
            .then(response => response.data)
            .catch((res) => {
                this.handleError(res, $t);
            })
    }

    async getBrokers($t) {
        return this.query('broker', $t);
    }

    async getTransaction(portfolioId, positionId, transactionId, $t) {
        const url = `/api/portfolio/${portfolioId}/position/${positionId}/transaction/${transactionId}`
        return await this
            .axios
            .get(url)
            .then((response) => response.data)
            .catch((res) => {
                this.handleError(res, $t);
            })
        }

    handleError(e, $t) {
        this.lastResponseCode = e.response?.status
        this.lastError = e.response?.data.error

        throw new Error($t('service.transaction.generalError', { error: this.lastError }));
    }
}

export default TransactionService
