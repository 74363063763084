
    import draggable from 'vuedraggable'
    import PortfolioService from "../../service/PortfolioService";
    import { backendExceptionHandler } from '@/mixins'
    import FormatterService from '@/service/FormatterService';
    import subscriptionConstants from '@/constants/subscription.constants'
    import PortfolioTab from '@/components/portfolio/PortfolioTab'
    import MobileDialog from '@/components/shared/MobileDialog'
    import PortfolioSecondaryKpi from "@/components/portfolio/PortfolioSecondaryKpi"
    import _ from 'lodash'

    const { subscribeProductKeys } = subscriptionConstants
    const tabItemBaseWidth = 85 // estimated based on padding, margin, icon...
    const tabCharacterWidth = 10 // estimated width for 1 character

    export default {
        name: "PortfolioTabs",
        mixins: [backendExceptionHandler],

        components: {
            draggable,
            PortfolioTab,
            MobileDialog
        },

        props: {
            portfolioId: String
        },

        data() {
            return {
                portfolios: [],
                portfolioTab: null,
                errorMessage: null,
                showArrows: true,
                sliderKey: true,
                assetMethodsIcons: null,
                showMorePortfolios: false,
                visiblePortfolios: [],
                invisiblePortfolios: [],
                showPortfolioMobileDialog: false,
                preLastVisibleItem: null,
                preFirstInvisibleItem: null,
                visibleDataListId: 'visible-portoflios-list-id',
                invisibleDataListId: 'invisible-portoflios-list-id',
                numberVisiblePortfolios: 0,
            }
        },

        computed: {
            showErrorMessage: {
                get() {return this.errorMessage !== null},
                set() {}
            },
            isMobile: {
                get() { return this.$vuetify.breakpoint.xsOnly },
                set() {}
            },
            tabIdDashboard() {
                const dashboardPageRouteName = this.$getTranslatedRouteName('finance-dashboard')
                const portfolioPageRouteName = this.$getTranslatedRouteName('finance-portfolio')
                if (!this.$route?.name?.includes(dashboardPageRouteName) &&
                    !this.$route?.name?.includes(portfolioPageRouteName)) {
                    return null
                }
                
                const tabId = this.$route.params.tab_id
                const originalTabId = this.$getOriginalTabId(tabId)
                if (['portfolios', 'stocks', 'performance'].includes(originalTabId)) {
                    return undefined
                }
                return tabId
            },
            individualAssetEnabled() {
                return this.$store.state.account.subscribedProductIds?.includes(subscribeProductKeys.individualAsset)
            },
            numberInvisiblePortfolios () {
                return this.invisiblePortfolios.length;
            },
            activePortfolioName() {
                if (this.$route.name === this.$getTranslatedRouteName('finance-dashboard-tab_id')) {
                    return this.$t('component.portfolio.portfolioTabs.dashboard');
                }
                if (this.portfolioId && this.portfolios) {
                    return this.portfolios.find(p => p.id == this.portfolioId)?.name || '';
                }
                return '';
            },
            dashboardTabUrl() {
                return {
                    name: this.$getTranslatedRouteName('finance-dashboard-tab_id'),
                    params: {
                        tab_id: this.tabIdDashboard
                    }
                }
            },
            showNumberInvisiblePortfolios() {
                return this.numberVisiblePortfolios != null && this.numberInvisiblePortfolios > 0;
            },
        },

        methods: {
            updateNumberVisiblePortfolios() {
                this.numberVisiblePortfolios = this.calculateVisibleTabs();  
            },  
            handleResize() {
                this.updateNumberVisiblePortfolios();  
            },
            calculateVisibleTabs() {
                if (this.$vuetify.breakpoint.smAndDown) {
                    return null;
                }

                const tabsContainer = this.$refs['v-tabs']?.$el;
                const portfolios = this.portfolios;
                if (!tabsContainer || !portfolios.length) {
                    return null
                }

                const containerWidth = tabsContainer.offsetWidth;
                let totalWidth = 0;  
                let visibleCount = 0;

                const dashboardTabWidth = this.$refs.dashboardTab.$el.offsetWidth;
                totalWidth += dashboardTabWidth;

                // Calculate how many portfolio tabs can fit  
                for (let i = 0; i < portfolios.length; i++) {  
                    const estimatedTabWidth = this.getEstimatedTabWidth(portfolios[i]);  
                    
                    if (totalWidth + estimatedTabWidth <= containerWidth) {  
                        totalWidth += estimatedTabWidth;  
                        visibleCount++;  
                    } else {  
                        break;  
                    }  
                }
                
                return visibleCount;
            },
            getEstimatedTabWidth(portfolio) {  
                return tabItemBaseWidth + (portfolio.name.length * tabCharacterWidth);  
            },
            optionalOn(){
                if(this.portfolioId && this.portfolios.every(p => p.id.toString() !== this.portfolioId)) {
                    return true
                }
                return false
            },

            async getPortfolioTabs() {
                try {
                    this.portfolios = await this.portfolioService.getTabs();
                    this.$store.commit('preferences/setPortfolioTabs', this.portfolios)
                    this.sliderKey = !this.sliderKey
                } catch(e) {
                    this.handleBackendException(e, this.$t('component.portfolio.portfolioTabs.fetchError'))
                }
                this.updateSelectedTab()
            },

            async onDragEndCallback(event) {
                const sourceListId = event.from.getAttribute('data-list-id');
                const targetListId = event.to.getAttribute('data-list-id');

                if (sourceListId === this.invisibleDataListId && targetListId === this.visibleDataListId) {
                    this.invisiblePortfolios.unshift(this.preLastVisibleItem);
                    this.visiblePortfolios =
                        this.visiblePortfolios.filter(p => p.id != this.preLastVisibleItem.id);
                } else if (sourceListId === this.visibleDataListId && targetListId === this.invisibleDataListId) {
                    this.visiblePortfolios.push(this.preFirstInvisibleItem);
                    this.invisiblePortfolios =
                        this.invisiblePortfolios.filter(p => p.id != this.preFirstInvisibleItem.id);
                }

                if (sourceListId !== targetListId) {
                    this.updateShowArrow()
                }

                const visiblePortfolioIds = this.visiblePortfolios.map(p => p.id);
                const invisiblePortfolioIds = this.invisiblePortfolios.map(p => p.id);
                
                try {
                    await this.portfolioService.setPositions([...visiblePortfolioIds, ...invisiblePortfolioIds])
                    this.errorMessage = null
                } catch(e) {
                    this.handleBackendException(e, this.$t('component.portfolio.portfolioTabs.saveOrderError'))
                }
            },

            updateSelectedTab() {
                if (!this.portfolioId) {
                    this.portfolioTab = '0'
                    return
                }
                this.portfolioTab = `${this.portfolioId}`
            },

            rewindSlider() {
                setTimeout(() => this.sliderKey = !this.sliderKey, 1000)
            },
            updateShowArrow() {
                if (this.$vuetify.breakpoint.smAndDown) {
                    return;
                }
                // Dispatch a resize event to force the slider to show the navigation arrows when needed
                const wrapperWidth = this.$refs['v-tabs'].$el.querySelector('.v-slide-group__wrapper').clientWidth
                const contentWidth = this.$refs['v-tabs'].$el.querySelector('.v-slide-group__content').offsetWidth
                window.dispatchEvent(new Event('resize'));

                this.showArrows = contentWidth > wrapperWidth
            }
        },

        created() {
            this.portfolioService = new PortfolioService(this.$axios)
            this.formatterService = new FormatterService(this.$axios);
            this.assetMethodsIcons = this.formatterService.getAssetMethodIcons();
            this.getPortfolioTabs()
            this.$root.$on('request-portfolio-tabs-refresh', this.getPortfolioTabs)
            this.$root.$on('request-rewind-slider', this.rewindSlider)
            this.debouncedHandleResize = _.debounce(this.handleResize, 200);  
        },
        mounted() {  
            this.updateNumberVisiblePortfolios();  
            window.addEventListener('resize', this.debouncedHandleResize);  
        },

        watch: {
            portfolioId() {
                this.updateSelectedTab()
            },
            portfolios(portfolios) {
                this.visiblePortfolios = portfolios.slice(0, this.numberVisiblePortfolios);
                this.invisiblePortfolios = portfolios.slice(this.numberVisiblePortfolios);
            },
            // this watcher will be triggered when resizing
            numberVisiblePortfolios(numberPortfolios) {
                if (this.portfolios.length) {
                    this.visiblePortfolios = this.portfolios.slice(0, numberPortfolios);
                    this.invisiblePortfolios = this.portfolios.slice(numberPortfolios);
                }
            },
            visiblePortfolios(_, oldItems) {
                if (oldItems.length > 0) {
                    this.preLastVisibleItem = oldItems[oldItems.length - 1]
                }
            },
            invisiblePortfolios(_, oldItems) {
                if (oldItems.length > 0) {
                    this.preFirstInvisibleItem = oldItems[0]
                }
            }
        },

        updated() {
            this.updateShowArrow()
        },

        beforeDestroy() {
            this.$root.$off('request-portfolio-tabs-refresh', this.getPortfolioTabs)
            this.$root.$off('request-rewind-slider', this.rewindSlider)
            window.removeEventListener('resize', this.debouncedHandleResize);  
        },
    }
