import { THEME_MODE_OPTIONS } from '../constants/theme.constants';

const state = () => ({
    themeMode: THEME_MODE_OPTIONS.system
});

const mutations = {
    setThemeMode(state, payload) {
        state.themeMode = payload
    }
};

const getters = {
    getThemeMode: (state) => {
        return state.themeMode
    }
};

export default {
    state,
    mutations,
    getters
}
