const state = () => ({
    benchmarks: []
});

const mutations = {
    setBenchmarks(state, payload) {
        state.benchmarks = payload
    }
};

const getters = {
    getBenchmarks: (state) => {
        return state.benchmarks
    }
};

export default {
    state,
    mutations,
    getters
}
