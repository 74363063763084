import { initMarketAnalysisKpiCardsConfiguration } from '@/constants/marketAnalysis'

const VERSION = 2

const state = () => ({
    pageSizes: {},
    kpisTotalsVisible: true,
    kpisPieChartsVisible: true,
    activeChartInterval: null,
    version: null,
    dividendYearSelected: null,
    kpiTotalData: null,
    chartTypePortfolioSelected: 'value',
    chartTypeShareDetailsSelected: 'value',
    portfolioTabs: [],
    locale: '',
    isPrivacyEnabled: false,
    marketAnalysisKpiCardsConfiguration: initMarketAnalysisKpiCardsConfiguration
})

const mutations = {
    pageSizeSelection(state, payload) {
        state.version = VERSION
        state.pageSizes[payload.name] = payload.size
    },
    setKpisTotalsVisibility(state, newValue) {
        state.version = VERSION
        state.kpisTotalsVisible = newValue
    },
    setKpisPieChartsVisibility(state, newValue) {
        state.version = VERSION
        state.kpisPieChartsVisible= newValue
    },
    activeChartInterval(state, newValue) {
        state.version = VERSION
        state.activeChartInterval= newValue
    },
    setDividendYearSelected(state, newValue) {
        state.version = VERSION
        state.dividendYearSelected= newValue
    },
    setKpiTotalData(state, newValue) {
        state.version = VERSION
        state.kpiTotalData= newValue
    },
    setChartTypePortfolioSelected(state, newValue) {
        state.version = VERSION
        state.chartTypePortfolioSelected= newValue
    },
    setChartTypeShareDetailsSelected(state, newValue) {
        state.version = VERSION
        state.chartTypeShareDetailsSelected= newValue
    },
    setPortfolioTabs(state, newValue) {
        state.version = VERSION
        state.portfolioTabs= newValue
    },
    setIsPrivacyEnabled(state, newValue) {
        state.version = VERSION
        state.isPrivacyEnabled = newValue
    },
    setLocale(state, newValue) {
        state.version = VERSION
        state.locale = newValue;
    },
    setMarketAnalysisKpiCardPosition(state, payload) {
        const { cardType, position } = payload
        const tempState = [...state.marketAnalysisKpiCardsConfiguration]
        tempState[position] = cardType

        state.version = VERSION
        state.marketAnalysisKpiCardsConfiguration = tempState
    }
}

const getters = {
    getPageSize: (state) => name => {
        if (state.version !== VERSION) {
            return undefined
        }
        return state.pageSizes[name]
    },
    getChartActiveInterval: (state) => {
        return state.activeChartInterval || 'day'
    },

    getDividendYearSelected(state) {
        const currentYear = new Date().getFullYear();
        return state.dividendYearSelected || currentYear
    },
    getKpiTotalData(state) {
        return state.kpiTotalData
    },
    getLocale: (state) => {
        return state.locale;
    },
    getMarketAnalysisKpiCardsConfiguration: (state) => {
        return state.marketAnalysisKpiCardsConfiguration
    }
};

export default {
    state,
    mutations,
    getters
}
