import localizationUils from "@/utils/localization.utils";
import localizationConfig from "@/config/localization.config";
const { AVAILABLE_LOCALE_CODES, FALLBACK_LOCALE } = localizationConfig;

const { detectBrowserLanguage } = localizationUils

export default function ({ isHMR, route, redirect, req }) {
    if (isHMR) {
        return
    }

    if (!route.name && route.path === '/') {
        const browserLanguage = detectBrowserLanguage(req)
        const lang = AVAILABLE_LOCALE_CODES.includes(browserLanguage) ? browserLanguage : FALLBACK_LOCALE;
        redirect({ name: lang })
    }
}