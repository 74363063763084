
    import { load } from "@finapi/web-form";

    export default {
        name: 'LivePortfolioImport',
        props: [
            'token',
            'finapiUrl'
        ],
        methods: {
            loadWebForm() {
                if (!this.token) {
                    return
                }
                let token = this.token
                if (window.location.hostname == 'localhost') {
                    token = 'invalid' // prevent endless request loop
                }
                this.$gtag?.event('sp_portfolio_finapi_webform_start', { value: 1 })
                load(this.$el, {
                        token: token,
                        targetUrl: this.finapiUrl,
                        // these are examples of how optional properties can be set:
                        layoutConfig: "xs",
                        customerSupportUrl: "https://finapi.io"
                    }, {
                        onComplete: function() {
                            this.$emit('update:status', 'completed')
                        }.bind(this),
                        onFail: function() {
                            this.$emit('update:status', 'failed')
                        }.bind(this),
                        onAbort: function() {
                            this.$emit('update:status', 'aborted')
                        }.bind(this),
                        onLoadError: function() {
                            this.$emit('update:status', 'load-error')
                        }.bind(this),
                    })
            },
        },
        mounted() {
            this.loadWebForm()
        },
        watch: {
            token() {
                this.loadWebForm()
            }
        }
    }
