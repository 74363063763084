
export default {
    name: "RingDiamondIcon",

    props: {
        color: {
            type: String,
            default: '',
        }
    },
};  
