export const kpiBoxCardTypes = {
    fearAndGreed: 'fear_and_greed',
    bullBearRatio: 'bull_bear_ratio',
    vix: 'vix',
    interestRate: 'interest_rate',
}

export const initMarketAnalysisKpiCardsConfiguration = [
    kpiBoxCardTypes.fearAndGreed,
    kpiBoxCardTypes.bullBearRatio,
    kpiBoxCardTypes.vix,
    kpiBoxCardTypes.interestRate,
]
