class UserService {
  lastError;
  lastResponseCode;
  constructor(axios) {
    this.axios = axios
  }

  async createPortalSession() {
    const url = '/api/payments/portal'

    return await this
      .axios
      .post(url)
      .then((res) => {
        this.lastResponseCode = res.status
        return res.data
      })
      .catch((error) => {
        return this.handleError(error)
      })
  }

  async createCheckout(productId) {
    const url = `/api/checkout/create/${productId}`

    return await this
      .axios
      .post(url)
      .then((res) => {
        this.lastResponseCode = res.status
        return res.data
      })
      .catch((error) => {
        return this.handleError(error)
      })
  }

  async trackPurchase(subscriptionId) {
    const url = `${location.origin}/api/checkout/purchase/${subscriptionId}`

    return await this
        .axios
        .get(url)
        .then((res) => {
          this.lastResponseCode = res.status
        })
        .catch((error) => {
          return this.handleError(error)
        })
  }

  async markAsRead(id) {
    const url = `/api/account/message/${id}/markAsRead`

    return await this
        .axios
        .put(url)
        .then((res) => {
          this.lastResponseCode = res.status
        })
  }

  async getMessages() {
    let url = '/api/account/messages'

    return await this
      .axios
      .get(url)
      .then((res) => {
        this.lastResponseCode = res.status
        return res.data
      })
  }

  async getUserMessage(seq) {
    let url = '/api/account/message'
    if (seq) {
      url += `?seq=${seq}`
    }
    return await this
      .axios
      .get(url)
      .then((res) => {
        this.lastResponseCode = res.status
        return res.data
      })
      .catch((error) => {
        return this.handleError(error)
      })
  }

  async postUserResponse(messageId, responseIndex) {
    const url = `/api/account/message/${messageId}/response`

    const data = {
      response: responseIndex
    };

    return await this
      .axios
      .post(url, data)
      .then((res) => {
        this.lastResponseCode = res.status
        return res.data
      })
      .catch((error) => {
        return this.handleError(error)
      })
  }

  handleError(e) {
    console.log(e)

    this.lastResponseCode = e.response?.status || e.code
    this.lastError = e.response?.statusText || e.message

    throw e
  }

  async updateViewTourUSer(body) {
    const url = `/api/account`;
    const data = {
      currency: body.currency,
      showNewUsersTour: body.showNewUsersTour
    };

    return await this
      .axios
      .put(url, data)
      .then((res) => {
        this.lastResponseCode = res.status
        return res.data
      })
      .catch((error) => {
        return this.handleError(error)
      })
  }
  
}
export default UserService
