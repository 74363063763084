  
export default {  
    name: "HomeIcon",

    props: {  
        color: {  
            type: String,  
            default: '',  
        }  
    },  
};  
