
import Alert from "@/components/Alert";

import { backendExceptionHandler, chartIntervalStateHandler } from '@/mixins'

export default {
    name: "PortfolioSecondaryKpi",
    components: {
        Alert
    },
    props: {
        portfolioId: String,
    },
    mixins: [backendExceptionHandler, chartIntervalStateHandler],

    computed: {
        portfolioName() {
            const portfolioTabs = this.$store.state.preferences.portfolioTabs;
            if (portfolioTabs?.length && this.portfolioId) {
                return portfolioTabs.find(p => p.id == this.portfolioId)?.name ?? '' ;
            }
            return '';
        },
        intervals() {
            return [
                {
                    interval: 'day',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.day')
                },
                {
                    interval: 'week',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.week')
                },
                {
                    interval: 'month',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.month')
                },
                {
                    interval: '3m',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.3m')
                },
                {
                    interval: '6m',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.6m')
                },
                {
                    interval: 'ytd',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.ytd')
                },
                {
                    interval: 'year',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.year')
                },
                {
                    interval: '2y',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.2y')
                },
                {
                    interval: '3y',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.3y')
                },
                {
                    interval: '4y',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.4y')
                },
                {
                    interval: 'fiveyears',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.fiveyears')
                },
                {
                    interval: '10y',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.10y')
                },
                {
                    interval: '20y',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.20y')
                },
                {
                    interval: 'max',
                    text: this.$t('component.portfolio.portfolioSecondaryKpi.intervals.max')
                }
            ]
        },
        interval() {
            return this.$store.getters["preferences/getChartActiveInterval"]
        }
    },

    data() {
        return {
            errorMessage: null,
            portfolio: null,
            secondaryTotals: {},
            loading: false
        }
    },

    methods: {
        intervalChanged(newInterval) {
            this.changeInterval(newInterval)
            this.$root.$emit('on-change-kpi-interval', newInterval)
        },
        
        updateErrorMessage() {
            this.errorMessage = '';
        },

        closeSelector() {
            const intervalSelector = this.$refs.intervalSelector;
            if (intervalSelector) {
                intervalSelector.blur();
            }  
        }
    },
}
