
import { backendExceptionHandler } from "@/mixins";
import Alert from "@/components/Alert";
import PortfolioService from '@/service/PortfolioService';
import FormatterService from '@/service/FormatterService';

export default {
    name: "PortfolioAssetTypeSelector",
    mixins: [backendExceptionHandler],
    components: {
        Alert
    },
    props: [
        "portfolioName",
    ],
    data() {
        return {
            errorMessage: null,
            isLoading: false,
            assetMethods: [],
            assetMethodsIcons: null,
            selectedAsset: null
        }
    },

    methods: {
        handleClickAsset(index) {
            this.selectedAsset = this.assetMethods[index].key;
        },
        async getAllMethodAsset() {
            try {
                this.isLoading = true;
                this.errorMessage = null;
                const response = await this.portfolioService.getAssetMethodsPortfolio();
                this.assetMethods = response?.filter(r => r.key !== 'portfolio');
                this.isLoading = false;
            } catch (error) {
                this.errorMessage = error;
            } finally {
                this.isLoading = false;
            }
        },

        updateErrorMessage() {
            this.errorMessage = ""
        },
    },
    created() {
        this.portfolioService = new PortfolioService(this.$axios);
        this.formatterService = new FormatterService();
        this.assetMethodsIcons = this.formatterService.getAssetMethodIcons();
        this.getAllMethodAsset();
    },

    watch: {
        portfolioName(newValue) {
            if (newValue) {
                this.selectedAsset = null;
            }
        },
        selectedAsset(value) {
            this.$emit('update:asset-group-key', value);
        }
    }
}

