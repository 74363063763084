const state = () => ({
    user: null,
    currency: null,
    subscribedProductIds: [],
    isSubscriber: false,
    showNewUsersTour: false,
    isTwoFaEnabled: false,
    credential: null,
    customIcon: null,
});

const mutations = {
    logout(state) {
        state.user = null;
    },
    login(state, user) {
        state.user = user
    },
    setCurrency(state, currency) {
        state.currency = currency
    },
    setSubscribedProductIds(state, subscribedProductIds) {
        state.subscribedProductIds = subscribedProductIds
    },
    setIsSubscriber(state, isSubscriber) {
        state.isSubscriber = isSubscriber
    },
    setShowNewUsersTour(state, showNewUsersTour) {
        state.showNewUsersTour = showNewUsersTour
    },   
    setIsTwoFaEnabled(state, isTwoFaEnabled) {
        state.isTwoFaEnabled = isTwoFaEnabled
    },
    setCredential(state, credential) {
        state.credential = credential
    },
    setCustomIcon(state, customIcon) {
        state.customIcon = customIcon
    },
};

export default {
    state,
    mutations,
}
