

    export default {
        name: "Alert",

        props: {
            message: String,
            type: String,
            autohide: {
                type: Boolean,
                default: false,
            },
        },

        created() {
            this.timeout = null
            this.setAutohide()
        },

        methods: {
            setAutohide() {
                clearTimeout(this.timeout)
                if (this.autohide) {
                    this.timeout = setTimeout(() => {
                        this.$emit('update:errorMessage', '');
                    }, 10000)
                }
            }
        },

        watch: {
            message() {
                this.setAutohide()
            }
        }
    }
