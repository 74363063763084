import { THEME_MODE_OPTIONS } from '../constants/theme.constants';

export default ({ $vuetify, store }) => {
    const setVuetifyThemeMode = ($vuetify, themeMode, settingThemeMode) => {
        if (themeMode === THEME_MODE_OPTIONS.system) {
            $vuetify.theme.dark = settingThemeMode === THEME_MODE_OPTIONS.dark
        } else {
            $vuetify.theme.dark = themeMode === THEME_MODE_OPTIONS.dark
        }
    }

    if (process.client) {
        let settingThemeMode =
            window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches
                ? THEME_MODE_OPTIONS.dark
                : THEME_MODE_OPTIONS.light;
        let themeMode = store.getters["theme/getThemeMode"]

        setVuetifyThemeMode($vuetify, themeMode, settingThemeMode)

        store.subscribe((mutation, state) => {
            if (mutation.type === "theme/setThemeMode") {
                themeMode = state.theme.themeMode
                setVuetifyThemeMode($vuetify, themeMode, settingThemeMode)
            }
        })

        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", event => {
            settingThemeMode = event.matches ? THEME_MODE_OPTIONS.dark : THEME_MODE_OPTIONS.light
            setVuetifyThemeMode($vuetify, themeMode, settingThemeMode)
        })
    }
};
