import de from '../locales/de.json'
import en from '../locales/en.json'

const FALLBACK_LOCALE = 'de';

const AVAILABLE_LOCALES = [
    { code: 'de', name: 'Deutsch', flag: 'de.png', translations: de },
    { code: 'en', name: 'English', flag: 'en.png', translations: en }
];

const AVAILABLE_LOCALE_CODES = AVAILABLE_LOCALES.map(l => l.code);

export default {
    FALLBACK_LOCALE,
    AVAILABLE_LOCALES,
    AVAILABLE_LOCALE_CODES
}
