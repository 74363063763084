
    import { backendExceptionHandler } from "@/mixins";

    export default {
        name: "IntroductionFuncionalityPortfolioModal",
        mixins: [ backendExceptionHandler ],

        data() {
            return {
                isVisible: false,
                loading: false,
                isAssetPortfolio: false
            }
        },

        methods: {
            openWindow(isAssetPortfolio) {
                this.isAssetPortfolio = isAssetPortfolio
                this.$nextTick(() => this.isVisible = true)
            },
            closeWindow() {
                this.isVisible = false;
            }
        },

        beforeMount() {
            this.$root.$on('request-introduction-functionality-portfolio-dialog', this.openWindow)
        },

        beforeDestroy() {
            this.$root.$off('request-introduction-functionality-portfolio-dialog', this.openWindow)
        },

        watch: {
            isVisible(newValue, oldValue) {
                if(newValue) return;
                const videoIntro = this.$refs.videoIntroFuncElement;
                const srcVideo = videoIntro.src;
                videoIntro.src = `${srcVideo}`; // reset value of src to pause video
            }
        }
    }

