export const state = () => ({
    baseUrl: '',
})

export const mutations = {
    setBaseUrl(state, baseUrl) {
        state.baseUrl = baseUrl
    },
}

export const getters = {
    baseUrl: (state) => state.baseUrl,
}
