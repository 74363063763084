
    export default {
        name: "FinApiDisclaimer",
        created() {
            this.DISCLAIMER_POINTS = [
                this.$t('component.portfolio.finApiDisclaimer.tuevCertified'),
                this.$t('component.portfolio.finApiDisclaimer.bafinApproved'),
                this.$t('component.portfolio.finApiDisclaimer.germanCompany'),
                this.$t('component.portfolio.finApiDisclaimer.readOnlyAccess'),
                this.$t('component.portfolio.finApiDisclaimer.accessRevocable')
            ]
        }
    }
