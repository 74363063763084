

export default {
    name: 'MobileDialog',

    props: {  
        value: {  
            type: Boolean,
            default: true  
        },
        title: {
            type: String,
            default: ''
        }
    },
}

