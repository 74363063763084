
    import { getAuth, onAuthStateChanged } from "firebase/auth"
    import firebaseApp from "@/firebaseApp"
    import { authStateHandler } from "@/mixins"
    
    const auth = getAuth(firebaseApp)

    export default {
        mixins: [ authStateHandler ],
        created() {
            this.unsubscribe = onAuthStateChanged(auth, this.authStateChanged)
        },
        beforeDestroy() {
            this.unsubscribe()
        },
    }
