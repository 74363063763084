
import AddPortfolio from "@/components/portfolio/AddPortfolio"

export default {
    name: 'EmptyLayout',
    components: {
        AddPortfolio
    },
    layout: 'empty',
    props: {
        error: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            pageNotFound: this.$t('layouts.error.pageNotFound'),
            otherError: this.$t('layouts.error.otherError'),
            isMounted: false
        }
    },
    computed: {
        logoPath() {
            return this.$vuetify.theme.dark ?
                require('@/assets/money-peak-logos/money-peak-white-error-logo.png') :
                require('@/assets/money-peak-logos/money-peak-black-error-logo.png');
        },
    },
    head () {
        const title = this.error.statusCode === 404
            ? this.$t('layouts.error.pageNotFound')
            : this.$t('layouts.error.otherError')
        return {
            title
        }
    },
    mounted() {
        const self = this
        self.$nextTick(function() {
            self.isMounted = true
        })
    }
}
