
import subscriptionConstants from '@/constants/subscription.constants'
import FormatterService from '@/service/FormatterService';
import PortfolioIconGenerator from '@/components/portfolio/icons/PortfolioIconGenerator'

const { subscribeProductKeys } = subscriptionConstants;

export default {
    name: "PortfolioTab",

    components: {
        PortfolioIconGenerator
    },

    props: {
        portfolio: {
            type: Object,
            default: () => {}
        },
    },

    data() {
        return {
            assetMethodsIcons: null,
        }
    },

    computed: {
        individualAssetEnabled() {
            return this.$store.state.account.subscribedProductIds?.includes(subscribeProductKeys.individualAsset);
        },
        tabId() {
            const dashboardPageRouteName = this.$getTranslatedRouteName('finance-dashboard')
            const portfolioPageRouteName = this.$getTranslatedRouteName('finance-portfolio')
            if (!this.$route?.name?.includes(dashboardPageRouteName) &&
                !this.$route?.name?.includes(portfolioPageRouteName)) {
                return null
            }
    
            return this.$route.params.tab_id;
        },
        portfolioId() {
            return this.$route.params?.portfolio_id
        },
        selectedIcon() {
            return this.portfolio?.icon
        },
        customIcon() {
            return this.portfolio?.customIcon
        },
    },

    methods: {
        getTabUrl(portfolio) {
            if (!this.individualAssetEnabled && portfolio.portfolioType === 'asset') {
                return {
                    name: this.$getTranslatedRouteName('feature-individuelle-assets')
                }
            }
            const tabId = this.tabId;
            return {
                name: this.$getTranslatedRouteName('finance-portfolio-portfolio_id-tab_id'),
                params: {
                    tab_id: tabId,
                    portfolio_id: portfolio.id
                }
            }
        }
    },

    created() {
        this.formatterService = new FormatterService();
        this.assetMethodsIcons = this.formatterService.getAssetMethodIcons();
    }
}
