const middleware = {}

middleware['authenticated-guard'] = require('../middleware/authenticated-guard.js')
middleware['authenticated-guard'] = middleware['authenticated-guard'].default || middleware['authenticated-guard']

middleware['i18n-routing'] = require('../middleware/i18n-routing.js')
middleware['i18n-routing'] = middleware['i18n-routing'].default || middleware['i18n-routing']

middleware['remove-double-slash'] = require('../middleware/remove-double-slash.js')
middleware['remove-double-slash'] = middleware['remove-double-slash'].default || middleware['remove-double-slash']

export default middleware
