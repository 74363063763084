import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
    Vue.use(VueGtag, {
        config: {
            id: process.env.NUXT_ENV_GOOGLE_ANALYTICS_KEY,
            params: { send_page_view: true }
        },
    }, app.router)
}
