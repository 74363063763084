class ConfigService {
    lastError = ''
    lastErrorCode = ''

    constructor(axios) {
        this.axios = axios
    }
    async getSignalCongfig() {
        return await this.axios
            .get(`/api/portfolio/notifications`)
            .catch(res => {
                this.#handleError(res)
            }); 
    }
    
    async updateSignalConfig(configs) {
        return await this.axios
            .put(`/api/portfolio/notifications`, configs)
            .catch(error => {
                if (error.response && error.response.data) {
                    return error.response;
                } else {
                    this.#handleError(error)
                }
            });
    }

    #handleError(e) {
        console.log(e)

        this.lastErrorCode = e.response?.status || e.code
        this.lastError = e.response?.statusText || e.message

        throw e
    }
}

export default ConfigService
