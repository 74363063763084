class CdnService {

    getLogoUrl(logo) {
        return 'https://' + process.env.NUXT_ENV_CDN_HOST + '/logos/' + logo;
    }

    getStockLogoUrl(logo) {
        return 'https://' + process.env.NUXT_ENV_CDN_HOST + '/mic/' + logo;
    }

}

export default CdnService
