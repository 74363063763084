const tabIdsEn = {
    stocks: 'stocks',
    transactions: 'transactions',
    realized: 'realized',
    dividends: 'dividends',
    performance: 'performance',
    allocation: 'allocation',
    kennzahlen: 'key-figures',
    'gewinn-und-verlust': 'profit-and-loss',
    bilanz: 'balance-sheet',
    cashflow: 'cashflow',
    ergebnis: 'earnings',
    'personalized': 'personalized',
    sentiment: 'sentiment',
}

const tabIdsDe = {
    stocks: 'aktien',
    transactions: 'transaktionen',
    realized: 'realisierte',
    dividends: 'dividenden',
    performance: 'performance',
    allocation: 'zuteilung',
    kennzahlen: 'kennzahlen',
    'gewinn-und-verlust': 'gewinn-und-verlust',
    bilanz: 'bilanz',
    cashflow: 'cashflow',
    ergebnis: 'ergebnis',
    'personalized': 'personalisiert',
    sentiment: 'marktstimmung',
}

const urlPathSegmentsEn = {
    aktie: 'stock',
    share: 'share',
    fundamentaldaten: 'fundamentaldata',
    checkout: 'checkout',
    create: 'create',
    success: 'success',
    feature: 'feature',
    individuelle: 'individual',
    assets: 'assets',
    'individuelle-assets': 'individual-assets',
    finance: 'finance',
    dashboard: 'dashboard',
    portfolio: 'portfolio',
    signup: 'signup',
    automatisch: 'auto',
    importieren: 'import',
    'feature-portfolio-automatisch-importieren': 'feature-portfolio-auto-import',
    feedback: 'feedback',
    maintenance: 'maintenance',
    user: 'user',
    settings: 'settings',
    profile: 'profile',
    index: 'en',
    'market-analysis': 'market-analysis',
    ...tabIdsEn
};

const urlPathSegmentsDe = {
    aktie: 'aktie',
    share: 'share',
    fundamentaldaten: 'fundamentaldaten',
    checkout: 'kasse',
    create: 'erstellen',
    success: 'erfolg',
    feature: 'feature',
    individuelle: 'individuelle',
    assets: 'assets',
    'individuelle-assets': 'individuelle-assets',
    finance: 'finanzen',
    dashboard: 'uebersicht',
    portfolio: 'portfolio',
    signup: 'registrieren',
    automatisch: 'automatisch',
    importieren: 'importieren',
    feedback: 'feedback',
    maintenance: 'wartung',
    user: 'benutzer',
    settings: 'einstellungen',
    profile: 'profil',
    index: 'de',
    'market-analysis': 'marktanalyse',
    ...tabIdsDe
};

const urlPathSegments = {
    en: urlPathSegmentsEn,
    de: urlPathSegmentsDe,
}

const tabIds = {
    en: tabIdsEn,
    de: tabIdsDe
}

const originalPublicRoutePaths = [
    'user-mgmt',
    'maintenance',
    'signup',
    'signup/success',
    'aktie',
    'market-analysis',
];

export default {
    urlPathSegments,
    originalPublicRoutePaths,
    tabIds
}