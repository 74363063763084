
    import { backendExceptionHandler } from "@/mixins";

    export default {
        name: "PositionenImportierenHelpCenterModal",
        mixins: [ backendExceptionHandler ],
        components: {

        },
        data() {
            return {
                isVisible: false,
                loading: false,
            }
        },
        methods: {
            openWindow(index) {
                this.$nextTick(() => this.isVisible = true)
            },
            closeWindow() {
                this.isVisible = false;
            },
            submitOnReturn(e) {
                if(e.key === "Enter" && this.isVisible) this.closeWindow()
            },

        },
        beforeMount() {
            this.$root.$on('request-help-center-positionen-importieren-dialog', this.openWindow)
            window.addEventListener('keydown', this.submitOnReturn)
        },

        beforeDestroy() {
            this.$root.$off('request-help-center-positionen-importieren-dialog', this.openWindow)
            window.removeEventListener('keydown', this.submitOnReturn)
        },

        watch: {
            isVisible(newValue, oldValue) {
                if(newValue) return;
                const videoIntro = this.$refs.videoIntroElement;
                const srcVideo = videoIntro.src;
                videoIntro.src = `${srcVideo}`
            }
        }
    }

