
    export default {
        name: "HelpCenter",
        computed: {
            isLoggedIn() {
                return !!this.$store.state.account.user
            },
            listHelpCenters() {
                return {
                    Dashboard: this.$t('component.helpCenter.codes.dashboard'),
                    PositionenImportieren: this.$t('component.helpCenter.codes.positionsImporting'),
                    MeinPortfolio: this.$t('component.helpCenter.codes.myPortfolio'),
                    Dividenden: this.$t('component.helpCenter.codes.dividens'),
                    PortfolioTeilen: this.$t('component.helpCenter.codes.portfolioTeilen'),
                    WerteVergleichen: this.$t('component.helpCenter.codes.compareValues'),
                    KPILeiste: this.$t('component.helpCenter.codes.kpiBar')
                };
            }
        },
        data() {
            return {
                isShowHelpCenter: false
            }
        },
        methods: {
            onClickOutside() {
                this.isShowHelpCenter = false;
            },
            openHelpCenterModal(key) {
                this.isShowHelpCenter = false;
                switch (key) {
                    case this.listHelpCenters.Dashboard:
                        this.$root.$emit('request-help-center-dashboard-dialog');
                        break;
                    case this.listHelpCenters.PositionenImportieren:
                        this.$root.$emit('request-help-center-positionen-importieren-dialog');
                        break;
                    case this.listHelpCenters.MeinPortfolio:
                        this.$root.$emit('request-help-center-mein-portfolio-dialog');
                        break;
                    case this.listHelpCenters.Dividenden:
                        this.$root.$emit('request-help-center-dividenden-dialog');
                        break;
                    case this.listHelpCenters.PortfolioTeilen:
                        this.$root.$emit('request-help-center-portfolio-teilen-dialog');
                        break;
                    case this.listHelpCenters.WerteVergleichen:
                        this.$root.$emit('request-help-center-werte-vergleichen-dialog');
                        break;
                    case this.listHelpCenters.KPILeiste:
                        this.$root.$emit('request-help-center-kPI-leiste-dialog');
                        break;
                    default:
                        break;
                }
            },
            onKeyUp(e) {
                if (e.key === 'Escape') {
                    this.isShowHelpCenter = false
                }
            },
        },
        beforeMount() {
            window.addEventListener('keyup', this.onKeyUp)
        },

        beforeDestroy() {
            window.removeEventListener('keyup', this.onKeyUp)
        }
    }
