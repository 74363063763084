
    import Vue from 'vue'
    import UserService from '@/service/UserService'
    import AuthService from "@/service/AuthService"
    import { backendExceptionHandler } from "@/mixins";
    import UserMessageDialog from "@/components/UserMessageDialog";
    import HelpCenter from "@/components/HelpCenter";

    export default {
        name: "AuthMenu",

        mixins: [backendExceptionHandler],
        components: {
            UserMessageDialog,
            HelpCenter
        },
        provide() {
            return {
                closeMenu: this.closeMenu,
            };
        },
        data() {
            return {
                isShowUserMenu: false,
                authBus: new Vue(),
                errorMessage: null,
                userMessage: null,
                showUserMessage: false,
            }
        },

        computed: {
            isLoggedIn() {
                return this.$store.state.account.user
            },
            email() {
                return this.$store.state.account.user.email
            },
            isSubscriber() {
                return this.$store.state.account.isSubscriber
            },
            isDarkMode() {
                return this.$vuetify.theme.dark
            },
            isTwoFaEnabled() {
                return this.$store.state.account.isTwoFaEnabled
            },
            userAvatarImagePath() {
                return this.$store.state.account.customIcon
            },
            userIconPath() {
                return this.$vuetify.theme.dark ?
                    require('@/assets/icons/user-icon-dark-mode.svg') :
                    require('@/assets/icons/user-icon-light-mode.svg')
            }
        },

        methods: {
            emitEvent(event) {
                this.authBus.$emit(event)
            },

            async goToCustomerPortal() {
                let url
                this.$gtag?.event(
                    'sp_subscr_customer_portal',
                    { value: 1 },
                )
                try {
                    url = await this.userService.createPortalSession()
                } catch (error) {
                    this.handleBackendException(
                        error, this.$t('component.auth.menu.cantAccessAccount')
                    )
                    return
                }
                window.location.replace(url)
            },

            redirectToLogin() {
                this.$router.push({
                    name: this.$getTranslatedRouteName('index'),
                })
            },

            redirectToFeedback() {
                if (this.$route.name === this.$getTranslatedRouteName('feedback')) {
                    return;
                }
                this.$router.push({
                    name: this.$getTranslatedRouteName('feedback'),
                })
            },

            redirectToSignup() {
                this.$router.push({
                    name: this.$getTranslatedRouteName('signup'),
                })
            },

            redirectToSettings() {
                this.$router.push({
                    name: this.$getTranslatedRouteName('settings'),
                })
            },

            redirectToProfile() {
                this.$router.push({
                    name: this.$getTranslatedRouteName('profile'),
                })
            },

            async logout() {
                try {
                    await AuthService.logout()
                    this.$store.commit('account/logout')
                    this.$store.commit('chart/setBenchmarks', [])
                    await this.$router.push({
                        name: this.$getTranslatedRouteName('index'),
                        params: {redirectMessage: 'Du wurdest ausgeloggt'},
                    })
                } catch(e) {
                    console.log(e)
                }
            },
            onKeyUp(e) {
                if (e.key === 'Escape') {
                    this.isShowUserMenu = false
                }
            },
            async openSystemMessagePopup(option = 'first') {
                try {
                    const response = await this.userService.getUserMessage(option);
                    
                    if (this.userService.lastResponseCode === 200) {
                        this.$gtag?.event('sp_usermessage_open', { value: 1 })
                        this.userMessage = response
                    } else {
                        this.userMessage = null
                    }
                } catch (e) {
                    this.handleBackendException(e, 'Es gab einen Fehler beim abrufen der User Message')
                }
            },
            closeMenu() {
                this.isShowUserMenu = false;
            },
        },

        created() {
            this.userService = new UserService(this.$axios)
        },

        beforeMount() {
            window.addEventListener('keyup', this.onKeyUp)
        },

        beforeDestroy() {
            window.removeEventListener('keyup', this.onKeyUp)
        },

        watch: {
            isLoggedIn() {
                this.openSystemMessagePopup(null);
            },
        },
    }
