import he from 'he'
import fundamentalConstants from '../constants/fundamental.constants'
import currencySymbols from './currency_symbols'

export const PRIVACY_DATA_MASKING = "XXX"

export default class FormatterService {
    #currency = 'EUR'
    #unit = null
    #locale = 'de-DE'
    isPrivacyEnabled = false

    constructor(opt = {}) {
        if (opt.currency) this.#currency = opt.currency
        if (opt.isPrivacyEnabled !== undefined) this.isPrivacyEnabled = opt.isPrivacyEnabled
    }

    updatePrivacyEnabled(isPrivacyEnabled) {
        this.isPrivacyEnabled = isPrivacyEnabled
    }

    updateCurrency(currency) {
        this.#currency = currency
    }

    getCurrencySymbol(currency) {
        if (currency && !currencySymbols[currency]) {
            return currency
        }
        
        return currency && currency !== 'IDX'
            ? he.decode(currencySymbols[currency])
                || Number().toLocaleString('en', { style: "currency", currency })
            : ''
    }

    currency(val, currency = null, instrumentType = null, extra = { shorten: true, isPrivacyEnabled: null }) {
        if (val === null || val === undefined) return "-";
        if (extra.isPrivacyEnabled === null) {
            extra.isPrivacyEnabled = this.isPrivacyEnabled
        }
        if (extra.isPrivacyEnabled) {
            return PRIVACY_DATA_MASKING + ' ' + (currency || '');
        }
        if (isNaN(val)) return val
        if (currency === null) currency = this.#currency

        const decimals = this.#getDecimalsByInstrumentType(instrumentType, val)

        const options = {
            currencyDisplay: 'code',
            maximumFractionDigits: decimals > 8 ? 8 : decimals
        }
        if (instrumentType != 'Import') {
            options.minimumFractionDigits = decimals > 8 ? 2 : decimals
        }
        if (currency && currency != 'IDX') {
            options.style = 'currency'
            options.currency = currency
        }
        /* Special cases, should check with Math.abs to make sure the negative value will be shorten too */
        if (extra.shorten && Math.abs(val) >= 1000000000000) {
            return new Intl.NumberFormat(this.#locale, {maximumFractionDigits: 2}).format(val/1000000000000) + ' T ' + (currency || '');
        }
        if (extra.shorten && Math.abs(val) >= 1000000000) {
            return new Intl.NumberFormat(this.#locale, {maximumFractionDigits: 2}).format(val/1000000000) + ' B ' + (currency || '');
        }
        if (extra.shorten && Math.abs(val) >= 1000000) {
            return new Intl.NumberFormat(this.#locale, {maximumFractionDigits: 2}).format(val/1000000) + ' M ' + (currency || '');
        }
        try {
            return new Intl.NumberFormat(this.#locale, options).format(val)
        }
        catch (error) {
            delete options.style
            delete options.currency
            return new Intl.NumberFormat(this.#locale, options).format(val) + ' ' + currency;
        }
    }


    #getDecimalsByInstrumentType = function (type, val = 0) {
        let decimals = 2

        if (type === 'Totals') decimals = 0
        if (type === 'Absolute Currency') decimals = 0
        if (type === 'Fundamental') decimals = 1
        if (type === 'Währung') decimals = 4
        if (type === 'Währung-ROI') decimals = 2
        if (type === 'Crypto Währung') decimals = 4
        if (type === 'Crypto') decimals = 4
        if (type === 'Import') decimals = 10


        if (val != 0) {
            const log10 = Math.log10(Math.abs(val))
            if (log10 < 0) {
                decimals = Math.max(Math.ceil(Math.abs(log10)), decimals)
            }
        }

        return decimals
    }

    numberToStringWithDecimals(val, instrumentType = null, decimals = null) {
        if (val === null || val === undefined) return null;
        if (decimals === null) {
            decimals = this.#getDecimalsByInstrumentType(instrumentType, val)
        }

        return val.toFixed(decimals).toString().replace(/\.?0+$/, '')
    }

    #getUnit = function (price) {
        const absPrice = Math.abs(price)
        
        if (absPrice < 1e-10) {
            this.#unit = 'n'
        } else if (absPrice < 1e-7) {
            this.#unit = 'u'
        } else if (absPrice < 1e-4) {
            this.#unit = 'm'
        } else if (absPrice > 1e10) {
            this.#unit = 'G'
        } else if (absPrice > 1e7) {
            this.#unit = 'M'
        } else if (absPrice > 1e4) {
            this.#unit = 'K'
        } else {
            this.#unit = ''
        }

        return this.#unit
    }

    extractFormattedNumber(stringNumber, locale) {
        const thousandSeparator = Intl.NumberFormat(locale || this.#locale).format(11111).replace(/\p{Number}/gu, '');
        const decimalSeparator = Intl.NumberFormat(locale || this.#locale).format(1.1).replace(/\p{Number}/gu, '');

        return parseFloat(stringNumber
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.')
        )
    }

    numberToAxisLabel(price, currency, instrumentType) {
        const dividers = {'n': 1e-9, 'u': 1e-6, 'm': 1e-3, '': 1, 'K': 1e3, 'M': 1e6, 'G': 1e9}
        let unit = this.#getUnit(price)
        const currencySymbol = this.getCurrencySymbol(currency)
        const priceFormat = Number(price).toFixed(8).replace(/\.?0+$/,"")
        const amount = this.currency(priceFormat / dividers[unit], 'IDX', instrumentType)
        
        if (!this.convertNumericToFloat(amount)) {
            unit = ''
        }

        if (this.isPrivacyEnabled) {
            return unit ? `${currencySymbol} ${PRIVACY_DATA_MASKING} ${unit}` : `${currencySymbol} ${PRIVACY_DATA_MASKING}`
        }

        return unit ? `${currencySymbol} ${amount} ${unit}` : `${currencySymbol} ${amount}`
    }

    percent(val) {
        if (val === null || val === undefined) return "-";
        let decimals = 2

        if (val !== null && Math.abs(val) * 100 < 1) {
            decimals += 2
        }

        const percentFormatter = new Intl.NumberFormat(this.#locale, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: decimals
        })

        return percentFormatter.format(val / 100)
    }

    date(opt) {
        let date = opt.date
        if (date === null) return 'n/a'
        if (!(date instanceof Date)) date = new Date(date)
        if (!date) throw new Error('Could not format Date')

        if (opt.format === 'dashSeparated') {
            return date.toISOString()
        }

        let locale = opt.locale || this.#locale
        let options = {
            dateStyle: 'medium'
        }

        if (opt.displayTime) {
            options.timeStyle = 'short'
        }

        try {
        return new Intl.DateTimeFormat(locale, options)
            .format(date)
            .replace(',', '')
        } catch (e) {
            return 'n/a'
        }
    }

    getChangeClass(change, withBackground = false) {
        let baseClassName = 'coloredChange'
        let colorClassName = ''

        if (change == '0' || change == '-' || !change) {
            colorClassName = baseClassName + '--neutral'
        } else if (change > 0) {
            colorClassName = baseClassName + '--positive'
        } else {
            colorClassName = baseClassName + '--negative'
        }

        let className = baseClassName + ' ' + colorClassName

        if (withBackground) {
            className = className + ' ' + colorClassName + '--bg'
        }

        return className
    }

    shorten(text, maxLength) {
        if (text && text.length > maxLength) {
            return text.slice(0, maxLength) + '...'
        }

        return text
    }

    camelCaseToPascalCase(name) {
        if (!name) {
            return name
        }
        return name
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
            .replace(/^./, str => str.toUpperCase())
    }

    autoFormat(value, format, instrumentType = null, currency = null, extra) {
        if (value !== 0 && !value) return ''

        if (format === 'currency') {
            return this.currency(value, currency ?? this.#currency, instrumentType, extra)
        }

        if (format === 'percent') {
            return this.percent(value)
        }

        if (format === 'quantity') {
            return value.toString().replace('.', ',')
        }

        return value
    }

    germanToIsoDate(value) {
        if (!value) return value
        return value.split('.').reverse().join('-')
    }

    convertNumericToFloat(value) {
        if (!value) return null
        if (typeof value === 'string') value = value
            .replace(',', '.')
            .replace(',', '.')

        return parseFloat(value)
    }

    parseLocaleNumber(stringNumber, locale) {
        if (!stringNumber
                || typeof stringNumber == 'number' && isNaN(stringNumber)) {
            return 0
        }
        if (typeof stringNumber == 'number') {
            return stringNumber
        }

        var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
        var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

        return parseFloat(stringNumber
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.')
        );
    }

    moveCurrencyToTop(currencies, key) {
        const item = currencies.find((item) => item?.currency === key);
        if (item) {
            currencies.unshift(item);
        }
    }

    getFormattedCurrencies(currencies = [], groupTitles = {}) {
        const formattedCurrencies = currencies.map(c => ({
            ...c,
            currency: c.currency,
            name: `${c.currency} (${c.name})`
        }))

        const sectionOthers = groupTitles.others || 'Weitere'
        const sectionTop = groupTitles.top || 'Häufig verwendet'

        formattedCurrencies.unshift({ header: sectionOthers })
        this.moveCurrencyToTop(formattedCurrencies, 'CHF')
        this.moveCurrencyToTop(formattedCurrencies, 'USD')
        this.moveCurrencyToTop(formattedCurrencies, 'EUR')
        formattedCurrencies.unshift({header: sectionTop})

        return formattedCurrencies
    }

    getAssetMethodIcons() {
        return {
            "immobilien": 'apartment.svg',
            "fahrzeuge": 'directions_car.svg',
            "kunst": 'hallway.svg',
            "p2p": 'handshake.svg',
            "immocroundfunding": 'groups.svg',
            "kredite": 'wallet.svg',
            "sammlerobjekte": 'watch.svg',
            "privateequity": 'pie_chart.svg',
            "cash": 'cash.svg',
            "darlehen": 'credit.svg',
            "companyinvestments": 'company_investments.svg',
        }
    }

    getInlineEditCleanText(innerHtml) {
        return innerHtml
            ?.replace(/&nbsp;/gi, ' ')
            ?.replace(/&amp;/gi, '&')
            ?.replace(/&lt;/gi, '<')
            ?.replace(/&gt;/gi, '>')
            ?.replace(/&zwnj;/gi, ' ')
            ?.replace(/<br>/gi, '')
            ?.replace(/<(.*?)>/g, '')
            ?.replace(/(<([^>]+)>)/ig, '')
            ?.trim()
    }

    formatFundamentalData(originValue, format) {
        const { fundamentalFormat } = fundamentalConstants;

        if (originValue === undefined || originValue === null) {
            return '-';
        }

        if (format === fundamentalFormat.relativeValue) {
            return this.percent(originValue * 100);
        }
        if (format === fundamentalFormat.absoluteCurrency) {
            return this.currency(originValue / 1000, '', 'Absolute Currency', { shorten: false });
        }

        if (format === fundamentalFormat.ratioValue) {
            return this.currency(originValue, '', 'Fundamental', { shorten: false });
        }

        // fundamentalFormat.currency
        return this.currency(originValue, '', null, { shorten: false });
    }
}
