
import AtomIcon from '@/components/portfolio/icons/AtomIcon'
import BankIcon from '@/components/portfolio/icons/BankIcon'
import BarChartCenterAlignedAlt3dIcon from '@/components/portfolio/icons/BarChartCenterAlignedAlt3dIcon'
import BootHeeledIcon from '@/components/portfolio/icons/BootHeeledIcon'
import BriefcaseIcon from '@/components/portfolio/icons/BriefcaseIcon'
import BuildingIcon from '@/components/portfolio/icons/BuildingIcon'
import BuildingStoreIcon from '@/components/portfolio/icons/BuildingStoreIcon'
import CameraIcon from '@/components/portfolio/icons/CameraIcon'
import CarRearIcon from '@/components/portfolio/icons/CarRearIcon'
import CouchIcon from '@/components/portfolio/icons/CouchIcon'
import CreditCardIcon from '@/components/portfolio/icons/CreditCardIcon'
import CurrencyBitcoinCircleIcon from '@/components/portfolio/icons/CurrencyBitcoinCircleIcon'
import DropletsIcon from '@/components/portfolio/icons/DropletsIcon'
import GiftIcon from '@/components/portfolio/icons/GiftIcon'
import GlobalMapIcon from '@/components/portfolio/icons/GlobalMapIcon'
import GraduationHatIcon from '@/components/portfolio/icons/GraduationHatIcon'
import HomeIcon from '@/components/portfolio/icons/HomeIcon'
import LightEmergencyOnIcon from '@/components/portfolio/icons/LightEmergencyOnIcon'
import MonitorIcon from '@/components/portfolio/icons/MonitorIcon'
import PhoneCallIcon from '@/components/portfolio/icons/PhoneCallIcon'
import PlaneIcon from '@/components/portfolio/icons/PlaneIcon'
import RingDiamondIcon from '@/components/portfolio/icons/RingDiamondIcon'
import RoadIcon from '@/components/portfolio/icons/RoadIcon'
import SafeIcon from '@/components/portfolio/icons/SafeIcon'
import ShirtLongSleeveIcon from '@/components/portfolio/icons/ShirtLongSleeveIcon'
import ShoppingBagIcon from '@/components/portfolio/icons/ShoppingBagIcon'
import TargetIcon from '@/components/portfolio/icons/TargetIcon'
import ToolIcon from '@/components/portfolio/icons/ToolIcon'
import UserIcon from '@/components/portfolio/icons/UserIcon'
import WalletIcon from '@/components/portfolio/icons/WalletIcon'

const PORTFOLIO_ICONS_MAPPING = {
    'wallet': WalletIcon,
    'bank': BankIcon,
    'credit-card': CreditCardIcon,
    'currency-bitcoin-circle': CurrencyBitcoinCircleIcon,
    'safe': SafeIcon,
    'gift': GiftIcon,
    'bar-chart-center-aligned-alt-3d': BarChartCenterAlignedAlt3dIcon,
    'graduation-hat': GraduationHatIcon,
    'briefcase': BriefcaseIcon,
    'atom': AtomIcon,
    'shopping-bag': ShoppingBagIcon,
    'boot-heeled': BootHeeledIcon,
    'shirt-long-sleeve': ShirtLongSleeveIcon,
    'couch': CouchIcon,
    'ring-diamond': RingDiamondIcon,
    'car-rear': CarRearIcon,
    'plane': PlaneIcon,
    'global-map': GlobalMapIcon,
    'monitor': MonitorIcon,
    'camera': CameraIcon,
    'phone-call': PhoneCallIcon,
    'home': HomeIcon,
    'building-store': BuildingStoreIcon,
    'building': BuildingIcon,
    'road': RoadIcon,
    'light-emergency-on': LightEmergencyOnIcon,
    'user': UserIcon,
    'target': TargetIcon,
    'tool': ToolIcon,
    'droplets': DropletsIcon,
}
export const PORTFOLIO_ICONS_KEYS = Object.keys(PORTFOLIO_ICONS_MAPPING)

export default {
    name: 'PortfolioIconGenerator',
    components: {
        AtomIcon,
        BankIcon,
        BarChartCenterAlignedAlt3dIcon,
        BootHeeledIcon,
        BriefcaseIcon,
        BuildingIcon,
        BuildingStoreIcon,
        CameraIcon,
        CarRearIcon,
        CouchIcon,
        CreditCardIcon,
        CurrencyBitcoinCircleIcon,
        DropletsIcon,
        GiftIcon,
        GlobalMapIcon,
        GraduationHatIcon,
        HomeIcon,
        LightEmergencyOnIcon,
        MonitorIcon,
        PhoneCallIcon,
        PlaneIcon,
        RingDiamondIcon,
        RoadIcon,
        SafeIcon,
        ShirtLongSleeveIcon,
        ShoppingBagIcon,
        TargetIcon,
        ToolIcon,
        UserIcon,
        WalletIcon,
    },
    props: {
        iconKey: {
            type: String,
            default: 'atom',
        },
        selected: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            portfolioIconsMapping: PORTFOLIO_ICONS_MAPPING
        }
    },
    computed: {
        primaryColor() {
            const theme = this.$vuetify.theme.dark ? 'dark' : 'light';
            return this.$vuetify.theme.themes[theme].iconPrimary;
        },
        selectedColor() {
            const theme = this.$vuetify.theme.dark ? 'dark' : 'light';
            return this.$vuetify.theme.themes[theme].iconOn;
        },
        filledColor() {
            return this.selected ? this.selectedColor : this.primaryColor
        }
    },
}
