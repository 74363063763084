import firebaseApp from "@/firebaseApp"
import { getAuth } from "firebase/auth"

const auth = getAuth(firebaseApp)

export default function ({ $axios }) {
    $axios.onRequest(async config => {
        if (!auth.currentUser) {
            return config
        }
        const result = await auth.currentUser.getIdTokenResult()
        // Firebase may sometimes be producing expired tokens - check explicitly:
        if (Date.parse(result.expirationTime) < Date.now()) {
            auth.currentUser.getIdTokenResult(true).then(result_3 => {
                $axios.setHeader('API-Authorization', `Bearer ${result_3.token}`)
            })
        } else {
            $axios.setHeader('API-Authorization', `Bearer ${result.token}`)
        }
        return config
    })
}