
export default {
    name: "SnackBar",

    props: {
        value: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            required: true,
            validator: (value) => ['success', 'error'].includes(value)
        },
        message: {
            type: String,
            required: true
        },
        timeout: {
            type: Number,
            default: 3000
        }
    },

    computed: {
        icon() {
            const iconMapping = {
                success: 'mdi-check-circle-outline',
                error: 'mdi-alert-outline'
            }
            return iconMapping[this.type]
        },
        color() {
            const colorMapping = {
                success: 'bgSnackbarSuccess',
                error: 'error'
            }
            return colorMapping[this.type]
        }
    },
};
