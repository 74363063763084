
    import SiteHeader from "~/components/SiteHeader";
    import SiteFooter from "~/components/SiteFooter";
    import { backendExceptionHandler } from "@/mixins"
    import DashboardHelpCenterModal from '@/components/helpcenter/DashboardHelpCenterModal';
    import PositionenImportierenHelpCenterModal from '@/components/helpcenter/PositionenImportierenHelpCenterModal';
    import MeinPortfolioHelpCenterModal from '@/components/helpcenter/MeinPortfolioHelpCenterModal';
    import DividendenHelpCenterModal from '@/components/helpcenter/DividendenHelpCenterModal';
    import PortfolioTeilenHelpCenterModal from '@/components/helpcenter/PortfolioTeilenHelpCenterModal';
    import WerteVergleichenHelpCenterModal from '@/components/helpcenter/WerteVergleichenHelpCenterModal';
    import KPILeisteHelpCenterModal from '@/components/helpcenter/KPILeisteHelpCenterModal';

    export default {
        name: "Page",
        components: { 
            SiteFooter,
            SiteHeader,
            DashboardHelpCenterModal,
            PositionenImportierenHelpCenterModal,
            MeinPortfolioHelpCenterModal,
            DividendenHelpCenterModal,
            PortfolioTeilenHelpCenterModal,
            WerteVergleichenHelpCenterModal,
            KPILeisteHelpCenterModal,
        },
        mixins: [backendExceptionHandler],
        computed: {
            isLoggedIn() {
                return this.$store.state.account.user
            },
        },
    }
