
    import AuthMenu from "@/components/auth/Menu";
import MobileMenu from "@/components/header/MobileMenu";
import PageNavigation from "@/components/header/PageNavigation";
import LocaleSelector from "@/components/LocaleSelector";
import Searchbar from "@/components/Searchbar";
import HeaderSnackbar from '@/components/shared/SnackBar';
import Brand from "./Brand";
import PortfolioTabs from "./portfolio/PortfolioTabs";

    export default {
        name: "SiteHeader",

        components: {
            Brand,
            AuthMenu,
            Searchbar,
            PortfolioTabs,
            PageNavigation,
            MobileMenu,
            LocaleSelector,
            HeaderSnackbar,
        },
        data() {
            return {
                isLanguageModalOpen: false,
                availableLocales: this.$getAvailableLocales(),
            }
        },
        computed: {
            inFeedbackPage() {
                return this.$route.name == this.$getTranslatedRouteName('feedback')
            },
            isLoggedIn() {
                return !!this.$store.state.account.user
            },
            portfolioId() {
                const financePortfolioRouteName = this.$getTranslatedRouteName('finance-portfolio')
                return this.$route.name?.indexOf(financePortfolioRouteName) !== -1
                    ? this.$route.params.portfolio_id
                    : '-1'
            },
            isMobile: {
                get() { return this.$vuetify.breakpoint.xsOnly },
            },
            isPrivacyEnabled() {
                return this.$store.state.preferences.isPrivacyEnabled
            },
            selectedLanguage() {
                const currentLocale = this.$store.getters['preferences/getLocale']
                return this.availableLocales.find(locale => locale.code === currentLocale)
            },
            showSnackbar() {                
                return !!this.$store.state.snackbar.message
            },
            showPortfolioTabs() {
                const originalPaths = ['settings', 'market-analysis', 'profile']
                const translatedPaths = originalPaths.map(path => this.$getTranslatedRoutePath(`/${path}/`))
                const currentPath = this.$route.path
                return !translatedPaths.some((path) => currentPath.includes(path))
            }
        },
        methods: {
            handleTogglePrivacy() {
                this.$store.commit('preferences/setIsPrivacyEnabled', !this.isPrivacyEnabled);
            },
            handleSnackbarEvent(event) {
                if (!event) {
                    this.$store.commit('snackbar/updateMessage', '');
                }
            }
        },
        created() {
            this.$store.commit('snackbar/updateMessage', '');
        }
    }
