
import AuthService from "@/service/AuthService";
import routingUtils from "@/utils/routing.utils";

const { directToTranslatedRoute } = routingUtils

export default {
    name: "LocaleSelector",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedLocale: this.$store.getters['preferences/getLocale'] || this.$getFallbackLocale(),
            availableLocales: this.$getAvailableLocales(),
        };
    },
    computed: {
        localeChanged() {
            return this.selectedLocale !== this.$store.getters['preferences/getLocale'];
        }
    },
    watch: {
        value() {
            this.selectedLocale = this.$getCurrentLocale()
        }
    },
    methods: {
        selectLocale(locale) {
            this.selectedLocale = locale;
        },
        async applyLocale() {
            this.$vuetify.lang.current = this.selectedLocale;
            const currentLocale = this.$getCurrentLocale()
            this.$setLocale(this.selectedLocale);
            directToTranslatedRoute({
                route: this.$route,
                router: this.$router,
                currentLocale,
                newLocale: this.selectedLocale
            })

            try {
                await AuthService.updateUserLanguage(this.selectedLocale, this.$axios, this.$t);
            } catch (error) {
                console.error("Failed to update language:", error);
            }
            this.$emit('input', false);
        },
        closeModal() {
            this.$emit('input', false);
        },
        onDialogInput(event) {
            this.$emit('input', event);
        },
        handleKeyDown(e) {
            if(e.key === "Enter" && this.value && this.localeChanged){
                this.applyLocale()   
            }
        },
    },
    beforeMount() {
        window.addEventListener('keydown', this.handleKeyDown)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown)
    },
};
